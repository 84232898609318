.client {
  width: 470px;
  height: 132px;
}

.client img {
  width: 70px;
  height: 70px;

  margin-right: 30px;
  float: left;
}

.client h5 {
  font-size: 16px;
  line-height: 26px;

  color: #0e183e;
}

.client h6 {
  margin-bottom: 14px;

  font-size: 16px;
  font-weight: 400;

  line-height: 26px;

  color: #5e778d;
}

.client p {
  padding-left: 100px;

  font-size: 14px;
  line-height: 23px;

  color: #5e778d;
}

@media only screen and (max-width: 750px) {
  .client {
    width: 630px;
    height: auto;
  }

  .client:last-child {
    display: none;
  }

  .client img {
    width: 91px;
    height: 91px;

    margin-right: 16px;
  }

  .client h5 {
    font-size: 28px;
    line-height: 46px;
  }

  .client h6 {
    margin-bottom: 12px;

    font-size: 28px;
    line-height: 46px;
  }

  .client p {
    padding: 0;

    font-size: 27px;
    line-height: 44px;
  }
}
