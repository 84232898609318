.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 366px;
  height: 468px;

  background-color: #323d66;
  border-radius: 20px;
  box-shadow: 0px 34px 60px rgba(0, 0, 0, 0.07);
}

.pic {
  width: 340px;
  height: 266px;
}

.content {
  height: 380px;
  position: relative;
  bottom: 105px;
}

.box button {
  position: relative;
  top: 30px;
  margin: 0;
}

@media only screen and (max-width: 750px) {
  .box {
    width: 480px;
    height: 580px;
  }

  .pic {
    position: relative;
    left: 25px;
    width: 400px;
    height: 326px;
  }

  .content {
    height: 450px;
    bottom: 145px;
  }

  .box button {
    width: 250px;
    height: 58px;
    position: relative;
    top: 50px;
    margin: 0;

    font-size: 21px;
  }

  .box button img {
    width: 32px;
  }
}
