.input {
  width: 231px;
  height: 48px;

  background: #fafafa;
  border: 1px solid #f3f3f3;
  border-radius: 10px;

  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 16px;

  text-align: center;

  color: #5e778d;
}

.input:focus {
  outline: none;
}

@media only screen and (max-width: 750px) {
  .input {
    width: 630px;
    height: 100px;

    font-size: 28px;
  }
}
