.videoContainer {
  width: 930px;
  height: 586px;

  text-align: center;

  margin-bottom: 90px;
}

.videoContainer h3 {
  font-size: 42px;
  color: #0e183e;
}

.videoContainer p {
  margin: 16px 0 32px 0;

  line-height: 26px;

  color: #5e778d;
}

.video {
  width: 930px;
  height: 457px;

  border-radius: 20px;
}

@media only screen and (max-width: 750px) {
  .videoContainer {
    width: 630px;
    height: 728px;

    margin-bottom: 90px;
  }

  .videoContainer h3 {
    font-size: 42px;
    line-height: 49px;
  }

  .videoContainer p {
    margin: 32px 0 32px 0;

    font-size: 28px;
    line-height: 46px;
  }

  .video {
    width: 630px;
    height: 341px;

    border-radius: 13px;
  }
}
