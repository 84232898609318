.dropdownBtn,
.dropdownNavbar {
  display: none;
}

@media only screen and (max-width: 750px) {
  .dropdownNavbar {
    padding-top: 30px;
    width: 750px;
    height: 2000px;

    position: absolute;
    top: 267px;
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #ffffff;

    z-index: 3;
  }

  .dropdownBtn {
    display: block;

    margin-right: 60px;

    background: none;
    border: none;
    cursor: pointer;
  }

  .dropdownBtn img {
    width: 52.5px;
    height: 37.5px;
  }

  .dropdownNavbar button {
    display: block !important;

    width: 350px;
    height: 82px;

    margin-right: 0;
    margin-top: 180px;

    font-family: "Montserrat", sans-serif;
    font-size: 28px;
  }

  .dropdownNavbar button img {
    width: 45px;
  }

  .dropdownNavbar div {
    margin-top: 120px;
    display: block !important;
  }

  .dropdownNavbar div img {
    width: 80px;
    height: 80px;
    margin: 0 10px 0 40px;
  }
}
