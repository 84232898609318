header {
  width: 100%;
  height: 90px;

  position: fixed;
  background-color: #fff;
  z-index: 3;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scrollable {
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 1440px) {
  header {
    padding-left: 100%;
    padding-right: 100%;
  }
}

.logo {
  width: 80px;
  height: 60px;

  margin: 0 132px 0 120px;
}

@media only screen and (max-width: 750px) {
  header {
    width: 750px;
    height: 114px;

    justify-content: space-between;

    position: static;

    margin-top: 130px;
    margin-bottom: 30px;
    padding: 0;
  }

  .scrollable {
    box-shadow: none;
  }

  .logo {
    width: 152px;
    height: 114px;

    margin: 0 0 0 60px;
  }
}
