.option {
  width: 274px;
  height: 235px;

  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 20px;

  transition: 0.5s;
}

.option:hover {
  transition: 0.5s;
  box-shadow: 0px 34px 60px rgba(0, 0, 0, 0.07);
}

.popularOption {
  border: 2px solid #ffd951;
}

.popular {
  margin: 25px 0 0 0;

  font-family: "Raleway";
  font-weight: 800;
  font-size: 14px;

  color: #ffd951;
}

.popularOption .period {
  margin-top: 11px;
}

.period {
  margin: 50px 0 0 0;
  font-family: "Montserrat", sans-serif;

  font-size: 18px;
  color: #0e183e;
}

.price {
  margin: 0;
  color: #0e183e;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.price span {
  font-size: 14px;
  font-weight: 400;
  color: #5e778d;
}

.description {
  margin: 17px 0 28px 0;
  font-family: "Raleway";
  font-size: 14px;
  color: #0e183e;
}

.btn {
  width: 122px;
  height: 32px;

  border: none;
  border-radius: 12px;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;

  font-weight: bold;
  font-size: 14px;

  color: #fafafa;

  background-color: #0e183e;
}

.btn span {
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 751px) {
  .btn div {
    background-color: #ffd951;

    position: absolute;
    right: 45%;
    top: 50%;
    width: 0;
    height: 0;
    border-radius: 100%;
    transition: 0.5s;
  }

  .btn:hover div {
    height: 300px;
    width: 300px;

    top: -105px;
    right: -60px;
    transition: 0.9s;

    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .option {
    width: 300px;
    height: 416px;
    margin-bottom: 30px;
  }

  .popularOption {
    border-width: 3px;
  }

  .popular {
    font-size: 18px;
  }

  .period {
    margin-top: 58px;

    font-size: 32px;
    font-weight: 400;
  }

  .price,
  .price span {
    font-size: 32px;
    margin-top: 30px;
  }

  .description {
    font-family: "Montserrat", sans-serif;

    font-size: 24px;
    line-height: 33px;

    margin: 30px 32px 30px 32px;
  }

  .option button {
    width: 196px;
    height: 50px;

    font-size: 26px;
  }
}
