.socialNetworkIcon {
  width: 24px;
  height: 24px;

  margin-right: 17px;
}

footer .socialNetworks {
  margin-right: 120px;
}

@media only screen and (max-width: 750px) {
  header .socialNetworks {
    display: none;
  }

  footer .socialNetworks {
    margin-right: 60px;
  }

  footer .socialNetworkIcon {
    width: 50px;
    height: 50px;
  }
}
