.total {
  width: 432px;
  height: 429px;

  margin-left: 100px;

  background: #fafafa;
  border-radius: 30px;
}

.orderInfo {
  height: 380px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  margin-left: 60px;
  margin-top: 30px;
}

.choosenBoxPic {
  width: 74px;
  height: 59px;

  margin-right: 28px;

  float: left;

  background-color: white;
}

.orderInfo h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;

  color: #0e183e;
}

.orderInfo p {
  font-family: "Montserrat", sans-serif;
  line-height: 26px;

  color: #0e183e;
}

.price {
  width: 210px;

  display: flex;
  justify-content: space-between;
}

.priceTag {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.promoCode {
  margin-top: 12px;
  padding: 0 0 0 30px !important;
  width: 180px !important;
  height: 48px !important;

  background: #f5f5f5 !important;
  border-radius: 10px !important;
}

.totalPrice {
  margin: 40px 0 18px 102px;
}

.submit {
  width: 210px;
  height: 48px;

  margin-left: 102px;

  background: #ffd951;
  border: none;
  border-radius: 50px;

  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 18px;

  color: #000000;

  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .total {
    margin-left: 0;
    width: 100%;
    height: 727px;
  }

  .choosenBoxPic {
    display: none;
  }

  .orderInfo {
    margin: 134px 60px 0 60px;
  }

  .orderInfo h5 {
    font-size: 50px;
  }

  .orderInfo p {
    font-size: 32px;
    line-height: 39px;
  }

  .price {
    width: 90%;
  }

  .promoCode {
    width: 301px !important;
    height: 100px !important;
  }

  .totalPrice {
    margin: 64px 0 71px 0px;
  }

  .submit {
    width: 100%;
    height: 90px;

    margin-left: 0;

    font-size: 32px;
  }
}
