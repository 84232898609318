.subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 609px;
  height: 209px;
}

.subscribe h3 {
  font-size: 42px;

  color: #0e183e;
}

.subscribe p {
  margin: 16px 0 70px 0;
  line-height: 26px;

  text-align: center;

  color: #5e778d;
}

.subscribe form {
  width: 434px;
}

.subscribe input {
  font-family: "Montserrat", sans-serif;
  padding-left: 41px;
  width: 434px;
  height: 48px;

  background: #f5f5f5;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  color: #5e778d;
}

.subscribe button {
  position: relative;
  bottom: 48px;
  left: 299px;

  width: 138px;
  height: 48px;

  background: #ffd951;
  border: none;
  border-radius: 30px;

  font-weight: 800;
  font-size: 16px;
  line-height: 26px;

  color: #0e183e;

  cursor: pointer;
}

.subscribe button:disabled {
  background-color: #f5f5f5;
  color: #d8d8d8;
  border: 1px solid #d8d8d8;
}

.subscribe input:focus {
  outline: none;
}

@media only screen and (max-width: 750px) {
  .subscribe {
    width: 630px;
    height: 251px;
  }

  .subscribe h3 {
    line-height: 49px;
  }

  .subscribe p {
    margin-bottom: 60px;
    font-size: 28px;
    line-height: 46px;
  }

  .subscribe form {
    width: 630px;
  }

  .subscribe input {
    padding-left: 48px;
    width: 570px;
    height: 80px;

    font-size: 28px;
  }

  .subscribe input::placeholder {
    font-size: 28px;
  }

  .subscribe button {
    position: relative;
    bottom: 80px;
    left: 420px;

    width: 217px;
    height: 80px;

    font-size: 28px;
  }
}
