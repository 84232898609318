.btn {
  background: #fafafa;
  border: 1px solid #f3f3f3;
  border-radius: 40px;

  cursor: pointer;
  transition: 0.5s;

  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;

  color: #000000;
}

.btn:hover {
  border: 1px solid #0e183e;
  box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  transition: 0.5s;
}

.small {
  width: 95px;
  height: 48px;

  margin-right: 16px;
}

.large {
  width: 203px;
  height: 48px;
}

.auto {
  padding: 11px 28px 11px 28px;
}

.active {
  background: #0e183e;
  color: #fff;
}

@media only screen and (max-width: 750px) {
  .btn {
    font-size: 28px;
  }

  .small {
    width: 160px;
    height: 100px;

    margin-right: 20px;
    margin-bottom: 20px;
  }

  .large {
    width: 299px;
    height: 100px;
  }

  .auto {
    padding: 27px 30px 27px 30px;
  }
}
