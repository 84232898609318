.boxButton {
  width: 196px;
  height: 46px;

  margin-left: 31px;

  font-weight: 800;
  font-size: 16px;

  background: #ffd951;
  box-shadow: 0px 13px 30px -10px #ffe486;
  border: none;
  border-radius: 0px 40px 40px 20px;

  cursor: pointer;
}

.boxButton a {
  padding: 10px 20px 10px 20px;
  color: #0e183e;
  text-decoration: none;
}

header .boxButton {
  margin: 0 120px 0 31px;
}

.boxButton span {
  vertical-align: middle;
}

.boxButton img {
  width: 26px;
  margin-left: 14px;

  vertical-align: middle;

  transition: 0.5s;
}

@media only screen and (min-width: 751px) {
  .boxButton:hover img {
    transform: translateX(5px);
    transition: 0.5s;
  }
}

@media only screen and (max-width: 750px) {
  header .boxButton {
    display: none;
  }
}
