.question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  padding: 40px 0 40px 0px;

  margin: 0 60px 32px 60px;

  width: 1200px;
  text-align: start;

  background: #fafafa;
  border-radius: 50px;

  cursor: pointer;
}

.question h5 {
  font-size: 18px;
  margin-left: 60px;
}

.question p {
  width: 80%;
  margin: 14px 0 0 60px;
  padding-left: 60px;
}

.question button {
  margin-right: 60px;
  border: none;
  background: none;
}

.question img {
  width: 20px;
  height: 10px;
}

.close p {
  display: none;
}

.open p {
  float: none;
  display: inline-block;
}

@media only screen and (max-width: 750px) {
  .question {
    padding: 30px 0 30px 0;
    margin-bottom: 32px;

    width: 720px;
  }

  .question h5 {
    font-size: 28px;
    margin-right: 30px;
  }

  .question p {
    width: 100%;
    margin-top: 32px;

    font-size: 28px;
    line-height: 46px;
  }
}
